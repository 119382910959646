<template>
  <view-wrapper header-text="Supporting <br /> healthy forests" class="support">
    <p class="intro"><strong class="support__film text-uppercase">
      The West is Burning </strong>was made possible by the generous support of these fine organizations and instituations.
    </p>
    <section class="support__organizations">
      <figure class="support__organization-logo">
        <img src="/img/logos/Wallowa-Resources-logo.png" alt="Wallowa Resources"/>
        <caption>
          Revitalizing rural communities through land and water stewardship, education and job creation.
        </caption>
      </figure>

      <figure class="support__organization-logo">
        <img src="/img/logos/RVCC-logo.png" alt="Rural Voices for Conservation Coalition"/>
        <caption>
          Envisioning healthy landscapes and vibrant rural communities across the American West.
        </caption>
      </figure>

      <figure class="support__organization-logo">
        <img src="/img/logos/UA-AES-logo.png" alt="The University of Arizona"/>
        <caption>
          Providing a diverse world-class infrastructure essential to generating and disseminating critical knowledge and technologies for Arizona and the world.
        </caption>
      </figure>

      <figure class="support__organization-logo">
        <img src="/img/logos/UA-LandmarkStories-logo.png" alt="Landmark Stories" style="max-width: 300px"/>
        <caption>
          A small award winning documentary team that believes in the power of sharing discoveries, struggles and life lessons that bring us closer together and closer to science.
        </caption>
      </figure>

      <figure class="support__organization-logo">
        <img src="/img/logos/Filmstacker-logo.png" alt="Filmstacker"/>
        <caption>
          Empowering and connecting storytellers across the globe through shared video.
        </caption>
      </figure>
    </section>
    <advisory-board></advisory-board>
  </view-wrapper>
</template>

<script>
  import AdvisoryBoard from "@/components/AdvisoryBoard/AdvisoryBoard"
  import IconInfo from "@/components/IconInfo/IconInfo"

  export default {
    name: "support",
    components: {
      AdvisoryBoard,
      IconInfo
    }
  }
</script>

<style lang="stylus">
  .support
    .icon-info__title
      display none
    .support__organizations
      margin-top 50px
    .support__organization-logo
      margin-bottom: 50px
      img
        max-width: 200px
        max-height: 90px
      caption
        width: 60%
        display: block
        text-align: left

      @media(max-width: $sm-breakpoint)
        caption
          width: 100%
</style>