<template>
  <div class="icon-info">
    <v-layout align-center class="mb-2">
      <v-icon color="info">{{ icon }}</v-icon>
      <h3 class="icon-info__title">{{ iconTitle }}</h3>
    </v-layout>
    <p>
      <slot></slot>
    </p>
  </div>
</template>

<script>
  export default {
    name: "icon-info",

    props: {
      iconTitle: String,
      icon: {
        type: String,
        default: "build"
      },
    },
  }
</script>

<style lang="stylus">
  .icon-info
    max-width: 450px
    &.last-child
      margin-bottom 0
    .v-icon
      font-size 60px
      margin-left 5px
    .icon-info__title
      margin-left 12px
      text-transform  uppercase
      font-size 20px
</style>