<template>
  <section class="advisory-board">
    <h2>Advisory Board</h2>
    <div class="advisory-board__person mb-4">
      <p class="advisory-board__title mb-0"><strong class="advisory-board__name">Nick Goulette</strong></p>
      <p>Watershed Research & Training Center</p>
    </div>
    <div class="advisory-board__person mb-4">
      <p class="advisory-board__title mb-0"><strong class="advisory-board__name">Alexander 'Zander' Evans</strong>, PhD</p>
      <p>Forest Stewards Guild</p>
    </div>
    <div class="advisory-board__person mb-4">
      <p class="advisory-board__title mb-0"><strong class="advisory-board__name">Donald Falk</strong>, PhD</p>
      <p>University of Arizona</p>
    </div>
    <div class="advisory-board__person mb-4">
      <p class="advisory-board__title mb-0"><strong class="advisory-board__name">Doug McDaniel</strong></p>
      <p>Wallowa Resources</p>
    </div>
    <div class="advisory-board__person">
      <p class="advisory-board__title mb-0"><strong class="advisory-board__name">Tyson Riggs</strong></p>
      <p>Rural Voices for Conservation</p>
    </div>
  </section>
</template>

<script>
  export default {
    name: 'advisory-board'
  }
</script>

<style lang="stylus">
  .advisory-board
    p
      font-size 14px
    .advisory-board__name
      font-size: 16px
</style>